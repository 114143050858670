<template>
  <div>
    <v-container>
      <v-layout row wrap justify-end>
        <v-flex xs12 sm4 md4 lg4 class="text-left">
          <h1 class="title light-blue--text text--darken-2 text-uppercase">
            {{ $t("stores") }}
          </h1>
        </v-flex>
        <v-spacer></v-spacer>
        <v-flex xs12 sm12 md4 lg4 class="text-right">
          <v-text-field
            v-model="search"
            dense
            solo
            :placeholder="$t('search stores')"
          ></v-text-field>
        </v-flex>
      </v-layout>
      <v-layout row wrap>
        <v-flex xs12 sm12 md12 lg12>
          <v-data-table
            :headers="headers"
            :loading="loading"
            :items="businesses"
            :search="search"
          >
            <template v-slot:[`item.logo`]="{ item }">
              <v-avatar size="30">
                <v-img :src="item.logo" style="cursor: pointer">
                  <template v-slot:placeholder>
                    <v-row
                      class="fill-height ma-0"
                      align="center"
                      justify="center"
                    >
                      <v-progress-circular
                        width="5"
                        indeterminate
                        :color="$store.state.primaryColor"
                      ></v-progress-circular>
                    </v-row>
                  </template>
                </v-img>
              </v-avatar>
            </template>

            <template v-slot:[`item.businessName`]="{ item }">
              <span class="font-weight-bold"> {{ item.businessName }}</span>
            </template>

            <template v-slot:[`item.verified`]="{ item }">
              <v-switch
                v-model="item.verified"
                :color="$store.state.secondaryColor"
                @change="verificationRight(item)"
                :loading="loading"
              ></v-switch>
            </template>

            <template v-slot:[`item.action`]="{ item }">
              <v-icon
                small
                left
                :color="$store.state.primaryColor"
                @click="viewIndividual(item.id)"
                >visibility</v-icon
              >
            </template>
          </v-data-table>
        </v-flex>
      </v-layout>

      <v-snackbar v-model="snackSuccess" dark top center timeout="4000">
        <span>{{ message }}</span>
        <template v-slot:action="{ attr }">
          <v-btn
            v-bind="attr"
            icon
            :color="$store.state.secondaryColor"
            @click="snackSuccess = false"
            ><v-icon>close</v-icon></v-btn
          >
        </template>
      </v-snackbar>
    </v-container>
  </div>
</template>

<script>
import db from "@/plugins/fb";

export default {
  data: () => ({
    loading: true,
    search: "",
    businesses: [],
    message: "",
    snackSuccess: false,
  }),

  created() {
    this.getBusinesses();
  },
  computed: {
    headers() {
      return [
        {
          text: this.$t("logo"),
          value: "logo",
          class: "blue-grey darken-3 white--text font-weight-regular",
        },
        {
          text: this.$t("name"),
          value: "businessName",
          class: "blue-grey darken-3 white--text font-weight-regular",
          sortable: false,
        },
        {
          text: this.$t("type"),
          value: "businessType",
          class: "blue-grey darken-3 white--text font-weight-regular",
          sortable: false,
        },
        {
          text: this.$t("registered on"),
          value: "dateCreated",
          class: "blue-grey darken-3 white--text font-weight-regular",
        },
        {
          text: this.$t("verification status"),
          value: "verified",
          class: "blue-grey darken-3 white--text font-weight-regular",
          sortable: false,
        },
        {
          text: this.$t("view"),
          value: "action",
          class: "blue-grey darken-3 white--text font-weight-regular",
          sortable: false,
        },
      ];
    },
  },
  methods: {
    getBusinesses() {
      db.collection("businesses")
        .where("ownerId", "==", this.$route.params.id)
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            this.businesses.push({
              id: doc.id,
              ...doc.data(),
            });
          });
          this.loading = false;
        });
    },

    verificationRight(data) {
      this.loading = true;
      db.collection("businesses")
        .doc(data.id)
        .update({
          verified: data.verified,
        })
        .then(() => {
          this.message = "Access Right updated successfully";
          this.snackSuccess = true;
          this.loading = false;
        });
    },

    viewIndividual(busID) {
      this.$router.push({ name: "StoreDetails", params: { busId: busID } });
    },
  },
};
</script>

<style></style>
