var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-container',[_c('v-layout',{attrs:{"row":"","wrap":"","justify-end":""}},[_c('v-flex',{staticClass:"text-left",attrs:{"xs12":"","sm4":"","md4":"","lg4":""}},[_c('h1',{staticClass:"title light-blue--text text--darken-2 text-uppercase"},[_vm._v(" "+_vm._s(_vm.$t("stores"))+" ")])]),_c('v-spacer'),_c('v-flex',{staticClass:"text-right",attrs:{"xs12":"","sm12":"","md4":"","lg4":""}},[_c('v-text-field',{attrs:{"dense":"","solo":"","placeholder":_vm.$t('search stores')},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1),_c('v-layout',{attrs:{"row":"","wrap":""}},[_c('v-flex',{attrs:{"xs12":"","sm12":"","md12":"","lg12":""}},[_c('v-data-table',{attrs:{"headers":_vm.headers,"loading":_vm.loading,"items":_vm.businesses,"search":_vm.search},scopedSlots:_vm._u([{key:"item.logo",fn:function(ref){
var item = ref.item;
return [_c('v-avatar',{attrs:{"size":"30"}},[_c('v-img',{staticStyle:{"cursor":"pointer"},attrs:{"src":item.logo},scopedSlots:_vm._u([{key:"placeholder",fn:function(){return [_c('v-row',{staticClass:"fill-height ma-0",attrs:{"align":"center","justify":"center"}},[_c('v-progress-circular',{attrs:{"width":"5","indeterminate":"","color":_vm.$store.state.primaryColor}})],1)]},proxy:true}],null,true)})],1)]}},{key:"item.businessName",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(item.businessName))])]}},{key:"item.verified",fn:function(ref){
var item = ref.item;
return [_c('v-switch',{attrs:{"color":_vm.$store.state.secondaryColor,"loading":_vm.loading},on:{"change":function($event){return _vm.verificationRight(item)}},model:{value:(item.verified),callback:function ($$v) {_vm.$set(item, "verified", $$v)},expression:"item.verified"}})]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{attrs:{"small":"","left":"","color":_vm.$store.state.primaryColor},on:{"click":function($event){return _vm.viewIndividual(item.id)}}},[_vm._v("visibility")])]}}],null,true)})],1)],1),_c('v-snackbar',{attrs:{"dark":"","top":"","center":"","timeout":"4000"},scopedSlots:_vm._u([{key:"action",fn:function(ref){
var attr = ref.attr;
return [_c('v-btn',_vm._b({attrs:{"icon":"","color":_vm.$store.state.secondaryColor},on:{"click":function($event){_vm.snackSuccess = false}}},'v-btn',attr,false),[_c('v-icon',[_vm._v("close")])],1)]}}]),model:{value:(_vm.snackSuccess),callback:function ($$v) {_vm.snackSuccess=$$v},expression:"snackSuccess"}},[_c('span',[_vm._v(_vm._s(_vm.message))])])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }